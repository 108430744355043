import React from 'react';

const Services = () => {
    return (
        <section id="servicesPage">
            <p class="zone">services</p>
        </section>
    );
};

export default Services;<p>services</p>