export const light = {
  body: "#fff",
  bodyInv: "#191919",
  text: "#202020",
  
  menuColor: "#f8f9fa",
  menuTextMobile: "#000",

  /*borderArrowTopColor: "#dcdedf",
  arrowTopColor: "#393939",*/
  borderArrowTopColor: "#dcdedf",
  arrowTopColor: "#373636",

  blueColor: "#bee0ec",

  fontxs: "0.75em",
  fontsm: "0.875em",
  fontmd: "1em", // 1em = 16px
  fontlg: "1.25em",
  fontxl: "2em",
  fontxxl: "3em",
  fontxxxl: "4em",

  navHeight: "5rem",
};

export const dark = {
  body: "#191919",
  bodyInv: "#717070",
  text: "#fff",

  menuColor: "#262828",
  menuTextMobile: "#000",

  borderArrowTopColor: "#373636",
  arrowTopColor: "#dcdedf",

  blueColor: "#3ba1c5",

  fontxs: "0.75em",
  fontsm: "0.875em",
  fontmd: "1em", // 1em = 16px
  fontlg: "1.25em",
  fontxl: "2em",
  fontxxl: "3em",
  fontxxxl: "4em",
  
  navHeight: "5rem",
};
