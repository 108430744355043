import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import '../src/styles/navigation.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMoon } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as SunSvg } from '../src/logos/sun.svg'

import { Fade as Hamburger } from 'hamburger-react'
import { styled } from 'styled-components';

let Section = styled.section`
  width: 100vw;
  background-color: ${(props) => props.theme.menuColor};
  color: ${(props) => props.theme.text};
  top: 0;
  position: fixed;
`;

let SectionOne = styled.section`
   width: fit-content;
   height: inherit;
   float: left;
   cursor: pointer;
`;

const Menu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  width: fit-content;
  margin-top: 22px;
  @media (max-width: 66em) {
    /* 1024 px */
    position: fixed;
    top: 4.375em;
    left: 0;
    right: 0;
    bottom: 0;
    width: 0px;
    height: ${(props) => `calc(100vh)`};
    z-index: 1;
    /*-webkit-backdrop-filter: blur(2em);
    backdrop-filter: blur(2em);*/
    margin: 0;
    padding: 0;
    ${(props) => props.click ? "width: 200px; transition: width 0.25s ease-in-out;" : "width: 0px; transition: width 0.25s ease-in-out;"};
    background-color: ${(props) => props.theme.menuColor};
    flex-direction: column;
    touch-action: none;
    overflow: hidden;
    box-shadow: 8px 0px 8px -8px black;
  }
`;

const MenuItem = styled.li`
  margin: 0 1rem;
  color: ${(props) => props.theme.text};
  cursor: pointer;

  &::after {
    content: " ";
    display: block;
    width: 0%;
    height: 2px;
    background: ${(props) => props.theme.text};
    transition: width 0.3s ease;
  }

  &:hover::after {
    width: 100%;
  }

  @media (max-width: 66em) {
    margin: 1rem 0;

    &::after {
      display: none;
    }
    width: 150px;
    text-align: center;
  }
`;

const MenuItem2 = styled.li`
  margin: 0 1rem;
  color: ${(props) => props.theme.text};
  cursor: pointer;

  @media (max-width: 66em) {
    margin: 1rem 0;

    &::after {
      display: none;
    }
    width: 150px;
    text-align: center;
  }
`;

let A = styled.a`
    text-decoration: none;
    color: ${(props) => props.theme.text};
`;

let A2 = styled.a`
    text-decoration: none;
    color: #202020 !important;
`;

let SectionHam = styled.section`
    float: right;
    margin-top: 6px;
    @media (min-width: 66em) {
        display: none;
    }
    display: block;
`;

let Section4 = styled.section`
    width: inherit;
    display: block;
    margin: 2rem 0;
    position: fixed;
    bottom: 0;
    overflow: hidden;
    padding: 25px 0;
    background-color: ${(props) => props.theme.blueColor};
    @media (min-width: 66em) {
        // 1268 px
        display: none;
    }
`;
let SectionPhone = styled.section`
    width: 50%;
    float: left;
    text-align: center;
`;
let SectionMail = styled.section`
    width: 50%;
    float: right;
    text-align: center;
`;

let SectionTrois = styled.section`
    width: fit-content;
    position: absolute;
    right: 0;
    top: 11px;
    padding-right: 15px;
    display: none;
    @media (min-width: 79.25em) {
        // 1268 px
        display: block;
    }
`;

let Button = styled.button`
    background-color: ${(props) => props.theme.menuColor};
    color: ${(props) => props.theme.text};
    fill: ${(props) => props.theme.text};

    @media (max-width: 66em) {
        color: ${(props) => props.theme.text};
        background-color: rgba(0,0,0,0);
        margin: 0;
    }
`;

const Navigation = React.forwardRef(({ updateThemeChild}, ref) => {
    const [getTheme, setTheme] = useState(true)
    const [click, setClick] = useState(false)
    const refOne = useRef(null)
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
      if(position !== scrollPosition) {
        console.log(click)
        setClick(false)
      }
    }

    const changeStateTheme = ( e ) => {
      if (e === "dark") {
        setTheme(false)
      } else {
        setTheme(true)
      }
    }

    useImperativeHandle(ref, () => ({
      changeStateTheme,
    }));

    useEffect(() => {
      // Fonction permetant la fermeture si click en dehors du menu en mobile
      document.addEventListener("click", closeMenuWhenClickOutside, true)
      // Fonction permetant la fermeture du menu si ouvert lors du scroll mobile
      window.addEventListener('scroll', handleScroll, { passive: true })
    }, [])

    const closeMenuWhenClickOutside = (e) => {
      if(!refOne.current.contains(e.target)) {
        setClick(false)
      }
    }

    const scrollTo = (id) => {
        let element = document.getElementById(id);
        let elementPosition = element.getBoundingClientRect().top;
        let offsetPosition = elementPosition + window.pageYOffset - 70;
        window.scrollTo({
          top:offsetPosition,
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
        if(click)
            setClick(!click);
    }

    const updateTheme = () => {
      setTheme(!getTheme)
      updateThemeChild()
      setClick(!click)
    }

    return (
        <Section id="topMenu" ref={refOne}>
            <SectionOne onClick={() => { scrollTo("topPage") }}>
                <section id="logoMenu">
                    <img
                        alt="img"
                        src="src/logos/logo.svg"
                        className="d-inline-block align-top marginImg logo"
                        id="imgLogo"
                    />
                </section>
                <section id="menuText">
                    <span id="textNameMenu">MCB<br/><span id="sousTextMenu">Christophe MONTENOT</span></span>
                </section>
            </SectionOne>

            <SectionHam>
                <Hamburger id="toggle" click={click} onClick={() => setClick(!click)} toggled={click} onToggle={() => setClick(!click)}/>
            </SectionHam>

            <Menu click={click}>
                <MenuItem><Button className='navLink navLinkPadding' id="top" onClick={() => { scrollTo("topPage") }}>Accueil</Button></MenuItem>
                <MenuItem><Button className='navLink navLinkPadding' id="about" onClick={() => { scrollTo("aboutPage") }}>A propos</Button></MenuItem>
                <MenuItem><Button className='navLink navLinkPadding' id="services" onClick={() => { scrollTo("servicesPage") }}>Nos services</Button></MenuItem>
                <MenuItem><Button className='navLink navLinkPadding' id="contact" onClick={() => { scrollTo("contactPage") }}>Nous contacter</Button></MenuItem>
                <MenuItem2><Button className='navLink navLinkPadding' onClick={() => { updateTheme() }}> { getTheme ? <FontAwesomeIcon icon={faMoon} /> : <SunSvg /> }</Button></MenuItem2>
                <Section4>
                    <SectionPhone>
                      <A2 href = "tel:0780743588">
                        <FontAwesomeIcon icon={faPhone} size="lg"/>
                      </A2>
                    </SectionPhone>
                    <SectionMail>
                      <A2 href = "mailto:mcb.plomberie@outlook.fr">
                        <FontAwesomeIcon icon={faEnvelope} size="lg"/>
                      </A2>
                    </SectionMail>
                </Section4>
            </Menu>

            <SectionTrois>
                    <div>
                        <section>
                            <span><FontAwesomeIcon icon={faPhone} /> 07.80.74.35.88</span>
                        </section>
                        <section>
                            <span><FontAwesomeIcon icon={faEnvelope} /> <A href = "mailto:mcb.plomberie@outlook.fr">mcb.plomberie@outlook.fr</A></span>
                            
                        </section>
                    </div>
            </SectionTrois>
            <sunSvg/>
        </Section>
    );
});

export default Navigation;