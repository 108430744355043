import React from 'react';

const About = () => {
    return (
        <section id="aboutPage">
            <p class="zone">A propos</p>
        </section>
    );
};

export default About;