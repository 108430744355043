import React, { useLayoutEffect, useRef } from "react";
import { useWindowScroll } from "react-use";
import { styled } from "styled-components";

const Up = styled.div` 
  width: 3rem;
  height: 3rem;

  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.arrowTopColor};
  background-color: ${(props) => props.theme.borderArrowTopColor};

  font-size: ${(props) => props.theme.fontxl};
  position: fixed;
  right: 1rem;
  bottom: 1rem;

  cursor: pointer;

  display: none;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.2);
  }
  &:active {
    transform: scale(0.9);
  }
  box-shadow: 3px 6px 13px ${(props) => props.theme.bodyInv};
`;

const ScrollToTop = () => {
  const ref = useRef(null);
  const { y } = useWindowScroll();

  const scrollToTop = () => {
    let element = document.getElementById("container");
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - 70;
    window.scrollTo({
      top:offsetPosition,
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  };

  useLayoutEffect(() => {
    if (y >= 200) {
      ref.current.style.display = "flex";
    } else {
      ref.current.style.display = "none";
    }
  }, [y]);
/*
<ThemeProvider theme={dark}>
</ThemeProvider>
*/
  return (
      <Up ref={ref} onClick={() => scrollToTop()}>
         &#x2191;
      </Up>
  );
};

export default ScrollToTop;
