import { ThemeProvider } from "styled-components"
import { light, dark} from "../src/themes/Themes"


import React, { useEffect, useRef, useState } from 'react';
import About from './About'
import '../src/styles/home.css'
import Services from './Services'
import Contact from './Contact'
import Home from './Home'
import ScrollToTop from '../components/ScrollToTop'
import Navigation from "../components/Navigation";

const MainPage = () => {

    const [getTheme, setTheme] = useState("light")
    const myRef = useRef();

    const updateTheme = () => {
        if(getTheme==="light") {
            document.querySelector("meta[name='theme-color']").content = "#262828"
            document.body.style.backgroundColor = "#191919"
            setTheme("dark")
        } else {
            document.querySelector("meta[name='theme-color']").content = "#f8f9fa"
            document.body.style.backgroundColor = "#fff"
            setTheme("light")
        }
    }

   
    /* darkmode with mobile settings */
    const setColorScheme = e => {
        // update state on menu component
        let themeColor
        if (e.matches) {
            themeColor = "dark"
            setTheme("dark")
            document.querySelector("meta[name='theme-color']").content = "#262828"
            document.body.style.backgroundColor = "#191919"
        } else {
            themeColor = "light"
            setTheme("light")
            document.querySelector("meta[name='theme-color']").content = "#f8f9fa"
            document.body.style.backgroundColor = "#fff"
        }
        myRef.current?.changeStateTheme(themeColor);
    }

    useEffect(() => {
        const colorSchemeQueryList = window.matchMedia('(prefers-color-scheme: dark)');
        setColorScheme(colorSchemeQueryList);
        colorSchemeQueryList.addEventListener('change', setColorScheme);
        return () => colorSchemeQueryList.removeEventListener("change", setColorScheme);
      }, []);
    

    return (
        <div id="container">
            <ThemeProvider theme={getTheme==="light"?light:dark}>
                <Navigation ref={myRef} updateThemeChild={updateTheme} />
                <Home />
                <About />
                <Services />
                <Contact />
                <ScrollToTop />
            </ThemeProvider>
        </div>
    );
};

export default MainPage;