import React from 'react';
import { styled } from "styled-components";

const Section = styled.p`
  color: ${(props) => props.theme.text};
  background-color: ${(props) => props.theme.body};
  ${console.log((props) => props.theme.body)};
`;

const Home = () => {
    return (
        <Section id="topPage">
            <p style={{padding: "15px"}}>
            Bienvenue chez [nom de votre entreprise], votre plombier de confiance pour tous vos besoins en plomberie. Nous sommes une entreprise familiale fière de servir la communauté depuis de nombreuses années.

            Nos plombiers qualifiés sont disponibles 24 heures sur 24 et 7 jours sur 7 pour répondre à toutes vos urgences de plomberie. Nous sommes équipés pour résoudre tous les problèmes de plomberie, des fuites d'eau aux tuyaux obstrués, en passant par les installations de chauffage et de climatisation.

            Nous sommes fiers d'offrir un service rapide, efficace et abordable à tous nos clients. Nous croyons que chaque client mérite un service de qualité supérieure, et nous travaillons dur pour nous assurer que chaque travail est effectué correctement et dans les délais impartis.

            Chez [nom de votre entreprise], nous sommes également engagés à utiliser les dernières technologies et techniques pour garantir que nos clients reçoivent le meilleur service possible. Nous sommes constamment en train de nous former et de nous tenir au courant des dernières tendances et innovations de l'industrie.

            Fiers de notre réputation de fournir un service de qualité supérieure à nos clients, et nous sommes impatients de vous aider avec tous vos besoins en plomberie. Contactez-nous dès aujourd'hui pour en savoir plus sur nos services ou pour planifier une intervention.
            </p>
        </Section>
    );
};

export default Home;