import React from 'react';

const Contact = () => {
    return (
        <section id="contactPage">
            <p class="zone">nous contacter</p>
        </section>
    );
};

export default Contact;